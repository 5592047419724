.MuiInputLabel-filled.MuiInputLabel-shrink {
    display: none;
}

.MuiInputLabel-filled {
    z-index: 1;
    transform: translate(12px, 10px) scale(1) !important;
    pointer-events: none;
    color: #646464 !important;
    font-size: 13px !important;
}
