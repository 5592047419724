.tippy-box {
    background-color: #fff;
    color: #1d1d1d;
    box-shadow: 0px 4px 40px rgba(74, 88, 109, 0.24);
    border-radius: 4px;
    padding: 6px 10px;
}

.tippy-arrow {
    color: #fff;
}