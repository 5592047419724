html, body {
    min-width: 320px;
}

::-webkit-scrollbar {
    width: 14px;
    height: 18px;
}
::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: #b2b2b3 ;
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
::-webkit-scrollbar-corner {
    background-color: transparent;
}

a {
    text-decoration: none;
    color: inherit;
}
.error404 {
    padding: 2em;
}

.error404 a {
    text-decoration: underline;
}

.MuiPickersTimePickerToolbar-separator {
    cursor: default;
    margin: 4px 2px !important;
}

.MuiPickersBasePicker-pickerView {
    align-self: center;
}

.MuiMenu-paper {
    max-height: 40vh !important;
    border-radius: 4px !important;
    margin: 4px 0 !important;
    max-width: 220px !important;
}

.MuiMenu-paper .MuiCheckbox-root {
    padding: 0;
    padding-right: 8px;
}

.MuiAutocomplete-option {
    word-break: break-word;
}